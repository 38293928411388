// JavaScript Document
jQuery( document ).ready(function($) {
    
	$(document).foundation();
	
	$('.hamburger').on('click', function(){
		
		$('.hamburger').toggleClass('is-active');
		$('.overlay-menu').toggleClass('is-active'); 
		$('html').toggleClass('locked');

	});
	
	//ACCORDION MOBILE MENU
	$('.is-accordion-submenu-parent a').click(function(){
        $(this).unbind('click');
	});
	
	$(window).scroll(function() {
        if ($(this).scrollTop() > 50)
        {
        $('.site-header').addClass("sticky");
        $('.corner-image').addClass("sticky");
        }
        else
        {
            $('.corner-image').removeClass("sticky");
        $('.site-header').removeClass("sticky");
        }
    });
	// Customizing a reveal set
	window.sr = ScrollReveal();
	
	sr.reveal('.rvl', {
		duration: 500,
		distance:'0px',
		scale:1,
		delay:100
		});
	
	sr.reveal('.str', { 
		duration: 500,
		origin: 'bottom',
		scale: 1
	}, 70);

	
});
